// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-browser-js": () => import("./../../../src/pages/browser.js" /* webpackChunkName: "component---src-pages-browser-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-accommodation-js": () => import("./../../../src/templates/accommodation.js" /* webpackChunkName: "component---src-templates-accommodation-js" */),
  "component---src-templates-attractions-category-js": () => import("./../../../src/templates/attractions-category.js" /* webpackChunkName: "component---src-templates-attractions-category-js" */),
  "component---src-templates-attractions-js": () => import("./../../../src/templates/attractions.js" /* webpackChunkName: "component---src-templates-attractions-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

